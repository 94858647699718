import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AmmGlobalNavBarComponent } from '../amm-global-nav-bar/amm-global-nav-bar.component';

@Component({
  selector: 'amm-global-nav-with-indicator',
  standalone: true,
  imports: [AmmGlobalNavBarComponent],
  templateUrl: './global-nav-with-indicator.component.html',
  styleUrl: './global-nav-with-indicator.component.scss',
  host: {style: "position: sticky;top:0px;z-index:1002;"}
})
export class GlobalNavWithIndicatorComponent {
  @Input()
  showNavIndicator: boolean = false;
  @Output()
  onNavIndicatorClick: EventEmitter<boolean> = new EventEmitter();
  onNavBarIndicatorClick() {
    this.onNavIndicatorClick.emit(true);
  }
}
